import * as React from 'react';
import { useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as Device from 'react-device-detect';
import { FormikProps } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { loginStatusVar } from '@context';
import * as FilterButtons from './FiltersButtons';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FiltersDialogProps {
  children?: React.ReactNode;
  count: number;
  formik: FormikProps<any>;
}

export const FiltersDialog = (props: FiltersDialogProps) => {
  const { children, count, formik } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation('cta');
  let [searchParams, setSearchParams] = useSearchParams();
  const { isLoggedIn } = useReactiveVar(loginStatusVar);

  const handlOpen = () => {
    setOpen(true);
  };

  const handlClose = () => {
    setOpen(false);
  };

  const handlApplyFilters = async () => {
    await formik.submitForm();
    handlClose();
  };

  const handlClearFilters = async () => {
    await formik.resetForm();
    handlClose();
  };

  return (
    <>
      {Device.isMobileOnly ? (
        <Chip
          icon={<theme.icons.filter fontSize="small" />}
          color="uncolored"
          label={t(`search.filters count`, { ns: 'common', count })}
          onClick={handlOpen}
        />
      ) : (
        <Button
          startIcon={<theme.icons.filter />}
          variant="contained"
          color="uncolored"
          fullWidth
          size="small"
          onClick={handlOpen}
        >
          {t(`search.filters count`, { ns: 'common', count })}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handlClose}
        fullScreen={Device.isMobile}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="md"
      >
        <Stack p={3} spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{t('search.Advanced search', { ns: 'common' })}</Typography>
            <Button
              variant="outlined"
              color="uncolored"
              size="small"
              startIcon={<theme.icons.close />}
              onClick={handlClose}
            >
              {t('close')}
            </Button>
          </Stack>
          <Stack>{children}</Stack>
          <Stack mt={2} spacing={2}>
            <FilterButtons.ApplyFilters
              count={count}
              isValid={formik.isValid}
              dirty={formik.dirty}
              handleClick={handlApplyFilters}
            />
            {isLoggedIn && (count > 0 || searchParams.size > 0) && (
              <FilterButtons.ClearFilters handleClick={handlClearFilters} />
            )}
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
