import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useReactiveVar } from '@apollo/client';

import { loginStatusVar } from '@context';
import { FilterAccordion } from './FilterAccordion';
import { FiltersDialog } from './FiltersDialog';
import * as FilterButtons from './FiltersButtons';
import * as FilterFields from './FilterFields';
import * as helpers from './helpers';
import { initFilters } from './initValues';
import getValidationSchema from './validationSchema';
import { Typography } from '@mui/material';

const filterKeySet = new Set(Object.values(helpers.KEYS).reduce((acc, keys) => [...acc, ...keys], []));

interface FiltersAdvancedProps {}

export const FiltersAdvanced = React.memo((props: FiltersAdvancedProps) => {
  const { t } = useTranslation('cta');
  const [count, setCount] = React.useState(0);
  const [expandedPanel, setExpandedPanel] = React.useState<string | false>(t(`title.Type`, { ns: 'spot' }));
  const { isLoggedIn } = useReactiveVar(loginStatusVar);
  let [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: initFilters(searchParams, isLoggedIn),
    validationSchema: getValidationSchema(t),
    validateOnMount: true,
    onReset: () => {
      setSearchParams({});
    },
    onSubmit: (filters) => {
      const search = searchParams.get('search') ?? 'spots';
      const page = searchParams.get('page') ?? '1';
      const validExisting = initFilters(searchParams, isLoggedIn);
      const { name, location } = validExisting;
      const validParams = helpers.removeNullOrEmpty({ name, location, ...filters });

      // Remove default numbervalues
      if (validParams.minCapacity === helpers.CAPACTITY_MIN) {
        delete validParams.minCapacity;
      }
      if (validParams.maxCapacity === helpers.CAPACTITY_MAX) {
        delete validParams.maxCapacity;
      }
      if (validParams.minCloseSpaces === helpers.CLOSE_SPACE_MIN) {
        delete validParams.minCloseSpaces;
      }
      if (validParams.maxCloseSpaces === helpers.CLOSE_SPACE_MAX) {
        delete validParams.maxCloseSpaces;
      }

      setSearchParams({ search, page, ...validParams });
    },
  });

  const handleChangePanel = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpandedPanel(newExpanded ? panel : false);
  };

  const handleToggleValue = (key: string, newValue: true | false | null) => {
    formik.setFieldValue(key, newValue);
  };

  const handleChangeSlider = (key: string, newValue: number) => {
    formik.setFieldValue(key, newValue);
  };

  const handleClearFilters = () => {
    formik.resetForm();
    setSearchParams({ search: 'spots' });
  };

  React.useEffect(() => {
    const countSet = Object.entries(formik.values).reduce((acc, [key, value]) => {
      if (filterKeySet.has(key)) {
        if (value === null) {
          acc.delete(key);
        } else if (typeof value === 'boolean') {
          acc.add(key);
        } else if (typeof value === 'number') {
          const isSliderNotDefault =
            (key === 'minCapacity' && value !== helpers.CAPACTITY_MIN) ||
            (key === 'maxCapacity' && value !== helpers.CAPACTITY_MAX) ||
            (key === 'minCloseSpaces' && value !== helpers.CLOSE_SPACE_MIN) ||
            (key === 'maxCloseSpaces' && value !== helpers.CLOSE_SPACE_MAX);
          if (isSliderNotDefault) {
            acc.add(key);
          } else {
            acc.delete(key);
          }
        }
      }
      return acc;
    }, new Set());

    setCount(countSet.size);
  }, [formik.values]);

  if (Device.isDesktop) {
    return (
      <Stack pb={2} spacing={2}>
        {!isLoggedIn && (
          <Paper width="100%" elevation={5} sx={{ p: 2, cursor: 'default' }}>
            <Typography variant="body2">
              💡 {t('search.Log in to use advanced filters', { ns: 'common' })}
            </Typography>
          </Paper>
        )}
        <form onSubmit={formik.handleSubmit}>
          {Object.entries(helpers.KEYS).map(([panel, keys]: [string, string[]]) => {
            if (panel === 'Configuration') {
              const count = helpers.getSliderFiltersCount([
                {
                  authorized: [helpers.CAPACTITY_MIN, helpers.CAPACTITY_MAX],
                  values: [formik.values.minCapacity as number, formik.values.maxCapacity as number],
                },
                {
                  authorized: [helpers.CLOSE_SPACE_MIN, helpers.CLOSE_SPACE_MAX],
                  values: [formik.values.minCloseSpaces as number, formik.values.maxCloseSpaces as number],
                },
              ]);

              return (
                <FilterAccordion
                  key={panel}
                  panel={t(`title.${panel}`, { ns: 'spot' })}
                  count={count}
                  expanded={expandedPanel}
                  handleChangePanel={handleChangePanel}
                >
                  <FilterFields.SliderRangeField
                    title={t(`title.Capacity`, { ns: 'spot' })}
                    minKey="minCapacity"
                    maxKey="maxCapacity"
                    authorizedMinValue={helpers.CAPACTITY_MIN}
                    authorizedMaxValue={helpers.CAPACTITY_MAX}
                    minValue={formik.values.minCapacity as number}
                    maxValue={formik.values.maxCapacity as number}
                    step={5}
                    minDistance={10}
                    handleChangeSlider={handleChangeSlider}
                  />
                  <FilterFields.SliderRangeField
                    title={t(`title.Closed space`, { ns: 'spot' })}
                    minKey="minCloseSpaces"
                    maxKey="maxCloseSpaces"
                    authorizedMinValue={helpers.CLOSE_SPACE_MIN}
                    authorizedMaxValue={helpers.CLOSE_SPACE_MAX}
                    minValue={formik.values.minCloseSpaces as number}
                    maxValue={formik.values.maxCloseSpaces as number}
                    step={1}
                    minDistance={1}
                    handleChangeSlider={handleChangeSlider}
                  />
                </FilterAccordion>
              );
            } else {
              const count = helpers.getFiltersCount(keys, formik.values);

              return (
                <FilterAccordion
                  key={panel}
                  panel={t(`title.${panel}`, { ns: 'spot' })}
                  count={count}
                  expanded={expandedPanel}
                  handleChangePanel={handleChangePanel}
                >
                  {keys.map((key, idx) => (
                    <FilterFields.TriStateCheckbox
                      key={idx}
                      keyValue={key}
                      label={t(`${panel.toLowerCase()}.${key}`, { ns: 'spot' })}
                      value={formik.values[key as keyof typeof formik.values] as boolean | null}
                      handleToggleValue={handleToggleValue}
                    />
                  ))}
                </FilterAccordion>
              );
            }
          })}

          <Stack mt={2} spacing={2}>
            <FilterButtons.ApplyFilters
              count={count}
              isValid={formik.isValid}
              dirty={formik.dirty}
              handleClick={formik.submitForm}
            />
            {isLoggedIn && (count > 0 || searchParams.size > 1) && (
              <FilterButtons.ClearFilters handleClick={handleClearFilters} />
            )}
          </Stack>
        </form>
      </Stack>
    );
  }

  return (
    <FiltersDialog count={count} formik={formik}>
      <Stack spacing={2}>
        {!isLoggedIn && (
          <Paper
            width="100%"
            elevation={5}
            sx={{ p: 2, cursor: 'default', backgroundColor: 'background.default' }}
          >
            <Typography variant="body2">
              💡 {t('search.Log in to use advanced filters', { ns: 'common' })}
            </Typography>
          </Paper>
        )}
        <form onSubmit={formik.handleSubmit}>
          {Object.entries(helpers.KEYS).map(([panel, keys]) => {
            if (panel === 'Configuration') {
              return (
                <FilterAccordion
                  key={panel}
                  panel={t(`title.${panel}`, { ns: 'spot' })}
                  count={helpers.getSliderFiltersCount([
                    {
                      authorized: [helpers.CAPACTITY_MIN, helpers.CAPACTITY_MAX],
                      values: [formik.values.minCapacity as number, formik.values.maxCapacity as number],
                    },
                    {
                      authorized: [helpers.CLOSE_SPACE_MIN, helpers.CLOSE_SPACE_MAX],
                      values: [
                        formik.values.minCloseSpaces as number,
                        formik.values.maxCloseSpaces as number,
                      ],
                    },
                  ])}
                  expanded={expandedPanel}
                  handleChangePanel={handleChangePanel}
                >
                  <FilterFields.SliderRangeField
                    title={t(`title.Capacity`, { ns: 'spot' })}
                    minKey="minCapacity"
                    maxKey="maxCapacity"
                    authorizedMinValue={helpers.CAPACTITY_MIN}
                    authorizedMaxValue={helpers.CAPACTITY_MAX}
                    minValue={formik.values.minCapacity as number}
                    maxValue={formik.values.maxCapacity as number}
                    step={5}
                    minDistance={10}
                    handleChangeSlider={handleChangeSlider}
                  />
                  <FilterFields.SliderRangeField
                    title={t(`title.Closed space`, { ns: 'spot' })}
                    minKey="minCloseSpaces"
                    maxKey="maxCloseSpaces"
                    authorizedMinValue={helpers.CLOSE_SPACE_MIN}
                    authorizedMaxValue={helpers.CLOSE_SPACE_MAX}
                    minValue={formik.values.minCloseSpaces as number}
                    maxValue={formik.values.maxCloseSpaces as number}
                    step={1}
                    minDistance={1}
                    handleChangeSlider={handleChangeSlider}
                  />
                </FilterAccordion>
              );
            } else {
              return (
                <FilterAccordion
                  key={panel}
                  panel={t(`title.${panel}`, { ns: 'spot' })}
                  count={helpers.getFiltersCount(keys, formik.values)}
                  expanded={expandedPanel}
                  handleChangePanel={handleChangePanel}
                >
                  {keys.map((key, idx) => (
                    <FilterFields.TriStateCheckbox
                      key={idx}
                      keyValue={key}
                      label={t(`${panel.toLowerCase()}.${key}`, { ns: 'spot' })}
                      value={formik.values[key as keyof typeof formik.values] as boolean | null}
                      handleToggleValue={handleToggleValue}
                    />
                  ))}
                </FilterAccordion>
              );
            }
          })}
        </form>
      </Stack>
    </FiltersDialog>
  );
});