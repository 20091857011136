import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';

import { useSession } from '@hooks';
import { NAVIGATION } from '@utils';

function samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

interface LinkTabProps {
  label?: string;
  href?: string;
  selected?: boolean;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (samePageLinkNavigation(event)) {
          event.preventDefault();
        }
      }}
      aria-current={props.selected && 'page'}
      {...props}
    />
  );
}

export function NavTabs() {
  const { account } = useSession();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tab, setTab] = React.useState(0);
  const { t } = useTranslation('common');
  const role = account?.__typename ?? 'Guest';

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const { href } = event.target as HTMLAnchorElement;
    const url = new URL(href, window.location.origin);
    const path = url.pathname + url.search;

    navigate(path, {
      state: { from: 'NavTabs' },
      flushSync: true,
      viewTransition: true,
    });
  };

  const lastSearch = localStorage.getItem('last-search') ?? 'search=spots&page=1';

  React.useEffect(() => {
    const role = account?.__typename ?? 'Guest';
    const idx = NAVIGATION[role].findIndex((path) => pathname.startsWith(path));

    if (idx === -1) {
      // navigate to the first tab if the current path is not in the list
      navigate(NAVIGATION[role][0], {
        state: { from: 'NavTabs' },
        viewTransition: true,
      });
    } else {
      setTab(idx);
    }
  }, [account, pathname]);

  if (Device.isMobileOnly) {
    return null;
  }

  if (role === 'Guest') {
    return (
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary"
          aria-label="nav tabs"
          role="navigation"
          selectionFollowsFocus
        >
          <LinkTab label="Spots" href="/spots" selected={tab === 0} />
          {/* 
            // tmp hide events tab
            <LinkTab label="Events" href="/events" selected={tab === 1} /> 
          */}
          <LinkTab label={t('word.Search')} href={`/search?${lastSearch}`} selected={tab === 1} />
        </Tabs>
      </Box>
    );
  }

  if (role === 'Consumer') {
    return (
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary"
          aria-label="nav tabs"
          role="navigation"
        >
          <LinkTab label="Spots" href="/spots" selected={tab === 0} />
          {/* 
            // tmp hide events tab
            <LinkTab label="Events" href="/events" selected={tab === 1} /> 
          */}
          <LinkTab label={t('word.Search')} href={`/search?${lastSearch}`} selected={tab === 1} />
          <LinkTab label="Agenda" href="/me/calendar" selected={tab === 2} />
        </Tabs>
      </Box>
    );
  }

  if (role === 'Spot') {
    return (
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary"
          aria-label="nav tabs"
          role="navigation"
        >
          <LinkTab label="Dashboard" href="/me/dashboard/preview" selected={tab === 0} />
          <LinkTab label="Bookings" href="/me/calendar/booking" selected={tab === 1} />
          <LinkTab label="Events" href="/me/calendar/event" selected={tab === 2} />
        </Tabs>
      </Box>
    );
  }

  if (role === 'Support') {
    return (
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary"
          aria-label="nav tabs"
          role="navigation"
        >
          <LinkTab label="dashboard" href="/support/dashboard" selected={tab === 0} />
          <LinkTab label="users" href="/support/users" selected={tab === 1} />
          <LinkTab label="Comptes" href="/support/accounts" selected={tab === 2} />
          <LinkTab label="events" href="/support/events" selected={tab === 3} />
          <LinkTab label="reports" href="/support/reports" selected={tab === 4} />
        </Tabs>
      </Box>
    );
  }

  // default fallback case
  return null;
}