import React from 'react';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';

import { loginStatusVar } from '@context';

interface TriStateCheckboxProps {
  keyValue: string;
  label: string;
  value: true | false | null;
  handleToggleValue: (key: string, newValue: true | false | null) => void;
}

interface SliderRangeFieldProps {
  title: string;
  authorizedMinValue: number;
  authorizedMaxValue: number;
  minValue: number | null;
  maxValue: number | null;
  minKey: string;
  maxKey: string;
  step: number;
  minDistance: number;
  handleChangeSlider: (key: string, newValue: number) => void;
}

export const TriStateCheckbox = React.memo((props: TriStateCheckboxProps) => {
  const { keyValue, label, value, handleToggleValue } = props;
  const loginStatus = useReactiveVar(loginStatusVar);
  const { t } = useTranslation('common');

  const getLabel = () => {
    switch (value) {
      case true:
        return t('word.yes');
      case false:
        return t('word.no');
      default:
        return t('word.never mind');
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!loginStatus.isLoggedIn) {
      return;
    }
    const { key } = event.currentTarget.dataset;
    const nextValue = value === true ? false : value === false ? null : true;
    handleToggleValue(key!, nextValue);
  };

  return (
    <Stack
      data-key={keyValue}
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleClick}
      sx={{ cursor: loginStatus.isLoggedIn ? 'pointer' : 'default' }}
    >
      <Typography fontSize=".8rem" color={loginStatus.isLoggedIn ? 'textPrimary' : 'textSecondary'}>
        {label}
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!loginStatus.isLoggedIn}
              checked={value === true} // Checkbox appears checked only for `true`
              indeterminate={value === null} // Show indeterminate state for `null`
              checkedIcon={<CheckBoxIcon color="success" />} // checked icon
              icon={<DisabledByDefaultIcon color="error" />} // unchecked icon
              indeterminateIcon={<CropSquareIcon color="uncolored" />} // indeterminate icon
            />
          }
          label={
            <Typography component="span" variant="body2" color="textSecondary" fontSize=".8rem">
              {getLabel()}
            </Typography>
          }
          labelPlacement="start"
        />
      </FormGroup>
    </Stack>
  );
});

function valuetext(value: number) {
  return `${value}°C`;
}

export const SliderRangeField = React.memo((props: SliderRangeFieldProps) => {
  const {
    title,
    authorizedMinValue,
    authorizedMaxValue,
    minValue,
    maxValue,
    minKey,
    maxKey,
    step,
    minDistance,
    handleChangeSlider,
  } = props;
  const [value, setValue] = React.useState<number[]>([
    minValue || authorizedMinValue,
    maxValue || authorizedMaxValue,
  ]);
  const loginStatus = useReactiveVar(loginStatusVar);

  const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  React.useEffect(() => {
    const [min, max] = value;

    if (min !== minValue) {
      handleChangeSlider(minKey, min);
    } else if (max !== maxValue) {
      handleChangeSlider(maxKey, max);
    }
  }, [value]);

  return (
    <Stack data-key={title} width="100%" justifyContent="space-between" alignItems="flex-start" p={2}>
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
        <Typography fontSize=".8rem">{title}</Typography>
        <Typography fontSize=".8rem">
          De {minValue} à {maxValue}
        </Typography>
      </Stack>
      <Slider
        disabled={!loginStatus.isLoggedIn}
        min={authorizedMinValue}
        max={authorizedMaxValue}
        step={step}
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ cursor: 'pointer' }}
          onClick={() => setValue([authorizedMinValue, value[1]])}
        >
          {authorizedMinValue} min
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ cursor: 'pointer' }}
          onClick={() => setValue([value[0], authorizedMaxValue])}
        >
          {authorizedMaxValue} max
        </Typography>
      </Stack>
    </Stack>
  );
});