export const CAPACTITY_MIN = 10;
export const CAPACTITY_MAX = 3000;
export const CLOSE_SPACE_MIN = 1;
export const CLOSE_SPACE_MAX = 50;

export const KEYS = {
  // tmp hide
  // Type: [
  //   // 'amusementPark',
  //   // 'aquarium',
  //   // 'artGallery',
  //   'bar',
  //   'cafe',
  //   // 'campground',
  //   // 'concertHall',
  //   // 'casino',
  //   // 'departmentStore',
  //   'nightClub',
  //   // 'park',
  //   'restaurant',
  //   // 'sportClub',
  //   // 'stadium',
  // ],
  Atmosphere: ['calm', 'lively', 'dancing'],
  Bringable: ['dogs', 'food', 'drinks', 'birthdayCake'],
  Configuration: ['minCapacity', 'maxCapacity', 'minCloseSpaces', 'maxCloseSpaces'],
  Highlight: ['mountainView', 'oceanView', 'rooftop', 'seaView', 'sunset', 'sunrise'],
  Payment: ['amex', 'cash', 'checks', 'blueCard', 'mealTickets'],
  Service: ['dj', 'karaoke', 'ownMusic', 'liveMusic', 'entertainmentShow', 'takeout'],
  Suitable: [
    'bachelor',
    'birthday',
    'farewell',
    'teamMeal',
    'wendding',
    'afterwork',
    'exposition',
    'cocktailPro',
    'fashionShow',
    'businessMeal',
    'companyEvent',
    'friendsDrink',
    'studentParty',
    'productLaunch',
    'kids',
    'watchSport',
    'telework',
    'veggie',
    'vegan',
  ],
  Equipment: [
    'amphitheater',
    'auditorium',
    'sono',
    'wifi',
    'stage',
    'video',
    'locker',
    'terrace',
    'toilets',
    'prmAccess',
    'dancefloor',
    'microphone',
    'prmToilets',
    'smokingRoom',
    'parking',
    'changingTable',
  ],
};

export const getTotalFiltersCount = (values: Record<string, string | boolean | number | null>) => Object.values(values).reduce((acc: number, value) => {
  if (typeof value === "string" || value === null) {
    return acc;
  } else if (typeof value === "number" && value < 1) {
    return acc;
  } else if (value === null) {
    return acc;
  }

  return acc + 1;
}, 0);

type SliderValues = {
  values: (number | null)[];
  authorized: number[];
}

export const getSliderFiltersCount = (toCheck: SliderValues[]) => toCheck.reduce((acc: number, { values, authorized }) => {
  const isMinDiff = values[0] !== null && values[0] !== authorized[0];
  const isMaxDiff = values[1] !== null && values[1] !== authorized[1];
  return acc + (isMinDiff || isMaxDiff ? 1 : 0);
}, 0);

export const getFiltersCount = (keys: string[], values: Record<string, string | boolean | number | null>) => keys.reduce((acc: number, key) => {
  const value = values[key];

  if (typeof value === "string" || value === null) {
    return acc;
  } else if (typeof value === "number" && value < 1) {
    return acc;
  } else if (value === null) {
    return acc;
  }

  return acc + 1;
}, 0);

// create a recursive that removes null values or empty strings
export function removeNullOrEmpty(obj: Record<string, any>): Record<string, any> {
  return Object.entries(obj).reduce((acc: Record<string, any>, [key, value]) => {
    if (value === null || value === '' || value === undefined) return acc;
    acc[key] = value;
    return acc;
  }, {});
}

export type AllowedValues = null | boolean | string | number;
export type FormValues = Record<string, AllowedValues>;

export const removeDefaultConfiguration = (incoming: FormValues) => {
  const CONF_KEYS = new Set(['minCapacity', 'maxCapacity', 'minCloseSpaces', 'maxCloseSpaces']);
  const result = { ...incoming };

  for (const key in incoming) {
    if (CONF_KEYS.has(key)) {
      const value = incoming[key];
      const isNumber = typeof value === 'number';
      const isNull = value === null;

      // init default number values
      if (key === 'minCapacity' && (isNull || !isNumber || (value as number) <= CAPACTITY_MIN)) {
        delete result[key];
      }
      if (key === 'maxCapacity' && (isNull || !isNumber || (value as number) >= CAPACTITY_MAX)) {
        delete result[key];
      }
      if (key === 'minCloseSpaces' && (isNull || !isNumber || (value as number) <= CLOSE_SPACE_MIN)) {
        delete result[key];
      }
      if (key === 'maxCloseSpaces' && (isNull || !isNumber || (value as number) >= CLOSE_SPACE_MAX)) {
        delete result[key];
      }
    }
  }

  return result;
};

export const populateValues = (base: FormValues, incoming: FormValues) => {
  const result = { ...base };

  for (const key in incoming) {
    if (key in base) {
      // if a key is found in source, update the value
      result[key] = incoming[key] ?? null;
    }

    const isNumber = typeof result[key] === 'number';
    const isNull = result[key] === null;

    // init default number values
    if (key === 'minCapacity' && (isNull || !isNumber || (result[key] as number) < CAPACTITY_MIN)) {
      result[key] = CAPACTITY_MIN;
    }
    if (key === 'maxCapacity' && (isNull || !isNumber || (result[key] as number) > CAPACTITY_MAX)) {
      result[key] = CAPACTITY_MAX;
    }
    if (key === 'minCloseSpaces' && (isNull || !isNumber || (result[key] as number) < CLOSE_SPACE_MIN)) {
      result[key] = CLOSE_SPACE_MIN;
    }
    if (key === 'maxCloseSpaces' && (isNull || !isNumber || (result[key] as number) > CLOSE_SPACE_MAX)) {
      result[key] = CLOSE_SPACE_MAX;
    }
  }

  // init default number values
  const minCapacity = typeof result.minCapacity === 'number' ? result.minCapacity : null;
  const maxCapacity = typeof result.maxCapacity === 'number' ? result.maxCapacity : null;
  const minCloseSpaces = typeof result.minCloseSpaces === 'number' ? result.minCloseSpaces : null;
  const maxCloseSpaces = typeof result.maxCloseSpaces === 'number' ? result.maxCloseSpaces : null;

  if (minCapacity === null || minCapacity < CAPACTITY_MIN) {
    result.minCapacity = CAPACTITY_MIN;
  }
  if (maxCapacity === null || maxCapacity > CAPACTITY_MAX) {
    result.maxCapacity = CAPACTITY_MAX;
  }
  if (minCloseSpaces === null || minCloseSpaces < CLOSE_SPACE_MIN) {
    result.minCloseSpaces = CLOSE_SPACE_MIN;
  }
  if (maxCloseSpaces === null || maxCloseSpaces > CLOSE_SPACE_MAX) {
    result.maxCloseSpaces = CLOSE_SPACE_MAX;
  }

  return result;
};

export const flattenObject = (obj: Record<string, any>): Record<string, any> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      return { ...acc, ...flattenObject(value) };
    }
    return { ...acc, [key]: value };
  }, {});
}