import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useToastError } from '@hooks';
import { useSupdateAccountMutation, useIsSlugAvailableLazyQuery, Account } from '@graphql';
import { TextField } from '@components';

interface NameSlugProps {
  id: string;
  slug: string;
}

export function NameSlug(props: NameSlugProps) {
  const { id, slug } = props;
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateAccount] = useSupdateAccountMutation();
  const [checkSlug] = useIsSlugAvailableLazyQuery();

  const formik = useFormik({
    initialValues: { slug },
    validationSchema: yup.object({
      slug: yup
        .string()
        .matches(/^[a-z0-9_-]+$/, t('slug.validation.matches', { ns: 'field' }))
        .min(3, t('slug.validation.min', { ns: 'field' }))
        .max(30, t('slug.validation.max', { ns: 'field' }))
        .required(t('slug.validation.required', { ns: 'field' })),
    }),
    onSubmit: (values) => {
      setTimeoutId(null);
      updateAccount({
        variables: { id, input: values },
        onCompleted() {
          formik.resetForm({ values });
          setIsEditing(false);
          navigate(`/support/accounts/${values.slug}/identity${location.search}`, {
            preventScrollReset: true,
            replace: true,
          });
        },
        onError: toastError,
      });
    },
  });

  React.useEffect(() => {
    setIsLoading(true);
    formik.setFieldTouched('slug', formik.values.slug !== slug);

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    if (formik.values.slug.length >= 3) {
      const newTmoutId = setTimeout(async () => {
        const { data } = await checkSlug({ variables: { slug: formik.values.slug } });

        formik.setFieldError(
          'slug',
          data?.isSlugAvailable === true ? undefined : t('slug.validation.unique', { ns: 'field' })
        );

        setIsLoading(false);
      }, 500);

      setTimeoutId(newTmoutId);
    } else {
      formik.setFieldError('slug', t('slug.validation.min', { ns: 'field' }));
      setIsLoading(false);
    }
  }, [formik.values.slug]);

  const handleChangeSlug = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('slug', e.target.value.toLowerCase());
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    formik.resetForm();
    setIsEditing(false);
  };

  if (!slug) {
    return null;
  }

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack width="100%" spacing={1}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack>
            <Typography fontWeight="bold">{t('slug.label', { ns: 'field' })}</Typography>
            <Typography variant="caption" color="text.secondary">
              {t('slug.caption', { ns: 'field' })}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="flex-start">
            {isEditing ? (
              <>
                <Button variant="contained" color="uncolored" size="small" onClick={handleCancel}>
                  {t('cancel')}
                </Button>
                <Button
                  disabled={!formik.dirty || !formik.isValid || isLoading}
                  variant="contained"
                  color="success"
                  size="small"
                  type="submit"
                >
                  {t('validate')}
                </Button>
              </>
            ) : (
              <Button variant="contained" color="uncolored" size="small" onClick={handleEdit}>
                {t('edit')}
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Stack>
            {isEditing ? (
              <TextField
                required
                fullWidth
                size="small"
                type="slug"
                name="slug"
                value={formik.values.slug}
                onChange={handleChangeSlug}
                error={Boolean(formik.touched.slug && formik.errors.slug)}
                helperText={formik.touched.slug && formik.errors.slug}
                slotProps={{
                  input: {
                    endAdornment: isLoading ? (
                      <InputAdornment position="end">
                        <CircularProgress size={20} color="info" />
                      </InputAdornment>
                    ) : null,
                  },
                }}
              />
            ) : (
              <Typography pb={1}>{'@' + slug}</Typography>
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">URL</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption" color="text.secondary">
                https://festayre.fr/
              </Typography>
              <Typography variant="caption">{isEditing ? formik.values.slug : slug}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">{t('slug.caption2', { ns: 'field' })}</Typography>
            <Typography variant="caption">{`@${isEditing ? formik.values.slug : slug}`}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}
