import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import * as Device from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import { useReactiveVar } from '@apollo/client';

import { useSearchEventsLazyQuery, useSearchSpotsLazyQuery } from '@graphql';
import { Slide, MobileSlide, SlideProps, OpenChip, OpenText } from '@components';
import { getTrueKeys } from '@utils';
import { loginStatusVar } from '@context';
import { useToastError } from '@hooks';
import * as helpers from './form-fields/spot';
import { initFilters, baseFilters } from './form-fields/spot/initValues';

const DEFAULT_IMG = [
  'bar-hut',
  'bar-pub',
  'bar-tapas',
  'bar',
  'bistrot',
  'cafe',
  'night-club',
  'pizzeria',
  'restaurant',
];

const getSpotFilters = (searchParams: URLSearchParams, isLoggedIn: boolean) =>
  helpers.removeDefaultConfiguration(
    helpers.removeNullOrEmpty({
      ...initFilters(searchParams, isLoggedIn),
      name: searchParams.get('name') ?? '',
      location: searchParams.get('location') ?? '',
      type: searchParams.get('type') ?? '',
    })
  );

const getEventFilters = (searchParams: URLSearchParams) =>
  helpers.removeNullOrEmpty({
    from: searchParams.get('from'),
    to: searchParams.get('to'),
    categories: searchParams.get('categories'),
    name: searchParams.get('name'),
    location: searchParams.get('location'),
  });

interface SearchResultsProps {
  search: string | null;
}

export const SearchResults = (props: SearchResultsProps) => {
  const { search } = props;
  const [results, setResults] = React.useState<SlideProps[]>([]);
  const [total, setTotal] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('cta');
  const { isLoggedIn } = useReactiveVar(loginStatusVar);
  const toastError = useToastError();

  const limit = 25;

  const [searchEvents, { loading: eventLoading, fetchMore: fetchMoreEvents }] = useSearchEventsLazyQuery({
    notifyOnNetworkStatusChange: true,
    onError: toastError,
    onCompleted({ searchEvents }) {
      const { total, events } = searchEvents;
      setTotal(total);
      setResults(
        events
          .map((event) => ({
            fullWidth: true,
            id: event.id,
            type: 'Event' as 'Event',
            to: `/search/event/${event.id}?${searchParams.toString()}`,
            title: event.title,
            covers: event.covers,
            defaultImage: null,
            subtitleLeft: moment(event.from).format('LLLL').split(' ').slice(0, 4).join(' '),
            subtitleRight: moment(event.from).format('LT'),
            textBottomLeft: event.spot?.name || '',
            textBottomRight: '',
            subTextBottomLeft: event.category?.name
              ? t('category.' + event.category?.name, { ns: 'event' })
              : '',
            subTextBottomRight: event.spot?.address?.city || event.address?.city || t('word.unknown city'),
          }))
          .filter((_, idx) => idx < 25)
      );
    },
  });

  const [searchSpots, { loading: spotLoading, fetchMore: fetchMoreSpots }] = useSearchSpotsLazyQuery({
    notifyOnNetworkStatusChange: true,
    onError: toastError,
    onCompleted({ searchSpots }) {
      const { total, spots } = searchSpots;
      setTotal(total);
      setResults(
        spots
          .map((spot) => ({
            fullWidth: true,
            id: spot.id,
            type: 'Spot' as 'Spot',
            to: `/search/spot/${spot.slug}?${searchParams.toString()}`,
            title: spot.name,
            covers: spot.covers,
            defaultImage: spot.configuration.primaryType ? spot.configuration.primaryType + '.jpg' : null,
            subtitleLeft: [
              spot.configuration.primaryType,
              spot.configuration.secondaryType,
              spot.configuration.tertiaryType,
            ]
              .filter(Boolean)
              .map((type) => t(`types.${type}.label`, { ns: 'spot' }))
              .join(' • '),
            subtitleRight: spot.address.city,
            textBottomLeft: getTrueKeys(spot.atmosphere)
              .map((key) => t(`atmosphere.${key}`, { ns: 'spot' }))
              .join(', '),
            textBottomRight: spot.configuration.pricing
              ? '€ ' + t(`pricing.${spot.configuration.pricing}`, { ns: 'spot' })
              : '€ ' + t(`word.${null}`, { ns: 'common' }),
            subTextBottomLeft: '',
            subTextBottomRight: '',
            SubBottomLeft: <OpenChip opening={spot.opening} closing={spot.closing} />,
            SubBottomRight: <OpenText opening={spot.opening} closing={spot.closing} />,
          }))
          .filter((_, idx) => idx < 25)
      );
    },
  });

  React.useEffect(() => {
    const page = Number(searchParams.get('page')); // falls back to 0 if null
    const search = searchParams.get('search');

    // handle no page or bad page
    if (search && page) {
      localStorage.setItem('last-search', searchParams.toString());

      if (search === 'spots') {
        if (!isLoggedIn) {
          const filtersFromUrl = getSpotFilters(
            searchParams,
            true // force isLoggedIn to true to get all url search params
          );

          const hasUnauthorizedFilters = Object.keys(filtersFromUrl).some((key) => !baseFilters.has(key));

          if (hasUnauthorizedFilters) {
            searchParams.forEach((value, key) => {
              if (!baseFilters.has(key)) {
                searchParams.delete(key);
              }
            });

            return setSearchParams(searchParams, { replace: true });
          }
        }

        setResults([]);
        searchSpots({
          fetchPolicy: 'cache-and-network',
          variables: {
            filters: getSpotFilters(searchParams, isLoggedIn),
            pagination: { page, limit },
          },
        });
      } else if (search === 'events') {
        setResults([]);
        searchEvents({
          fetchPolicy: 'cache-and-network',
          variables: {
            filters: getEventFilters(searchParams),
            pagination: { page, limit },
          },
        });
      }
    }
  }, [searchParams, isLoggedIn]);

  const handlePageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  if (!results.length || spotLoading || eventLoading) {
    return (
      <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
        {spotLoading || eventLoading ? (
          <CircularProgress color="primary" />
        ) : (
          <Typography variant="body2" color="textSecondary" textAlign="center" fontStyle="italic">
            {'Pas de résultat'}
          </Typography>
        )}
      </Stack>
    );
  }

  if (Device.isDesktop) {
    return (
      <Stack width="100%" spacing={2} pb={3}>
        <Stack direction="row" width="100%">
          <Grid container spacing={2} p={2} width="100%">
            {results.map((result) => (
              <Grid key={result.id} item="true" size={{ xs: 6, sm: 6, md: 6, xl: 4 }}>
                {Device.isMobileOnly ? <MobileSlide {...result} /> : <Slide {...result} />}
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Stack direction="row" flex={1} justifyContent="center">
          <Pagination
            color="primary"
            shape="rounded"
            count={Math.ceil(total / limit)}
            page={Number(searchParams.get('page')) || 1}
            onChange={(_, page) => handlePageChange(page)}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Grid container pb={8} spacing={2} width="100%">
      {results.map((result) => (
        <Grid key={result.id} item="true" size={{ xs: 12, sm: 12, md: 6, xl: 4 }}>
          {Device.isMobileOnly ? <MobileSlide {...result} /> : <Slide {...result} />}
        </Grid>
      ))}
      <Stack direction="row" flex={1} justifyContent="center">
        <Pagination
          color="primary"
          shape="rounded"
          count={Math.ceil(total / limit)}
          page={Number(searchParams.get('page')) || 1}
          onChange={(_, page) => handlePageChange(page)}
        />
      </Stack>
    </Grid>
  );
};
