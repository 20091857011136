import * as React from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import * as Device from 'react-device-detect';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import * as DateFormFields from './DateFormFields';
import { SpotTypeEnum } from '@graphql';
import { EventCategoryField } from './EventCategoryField';
import { NameFormField } from './NameFormField';
import { FiltersAdvanced } from './spot/FiltersAdvanced';
import { LocationFormField } from './LocationFormField';
import { TypeFormField } from './TypeFormField';

export const SearchEventForm = () => {
  const theme = useTheme();
  const { t } = useTranslation('cta');

  if (Device.isMobileOnly) {
    return (
      <Stack spacing={2}>
        <NameFormField />
        <Stack direction="row" spacing={2} justifyContent="center">
          <SwipeDrawer
            label={t('search.date range', { ns: 'common' })}
            icon={<theme.icons.timelapse fontSize="small" />}
            children={
              <Stack direction="row" spacing={2}>
                <DateFormFields.FromPicker />
                <DateFormFields.ToPicker />
              </Stack>
            }
          />
          <SwipeDrawer
            label={t('search.categories', { ns: 'common' })}
            icon={<theme.icons.list fontSize="small" />}
            children={<EventCategoryField />}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <LocationFormField />
      <NameFormField />
      <Stack direction="row" spacing={2}>
        <DateFormFields.FromPicker />
        <DateFormFields.ToPicker />
      </Stack>
      <EventCategoryField />
    </Stack>
  );
};

export const SearchSpotForm = () => {
  const theme = useTheme();
  const { t } = useTranslation('cta');
  let [searchParams] = useSearchParams();
  const validTypes = new Set(Object.values(SpotTypeEnum));

  if (Device.isMobileOnly) {
    return (
      <Stack spacing={2}>
        <NameFormField />
        <Stack direction="row" spacing={2} justifyContent="center">
          <SwipeDrawer
            label={t('search.localisation', { ns: 'common' })}
            icon={<theme.icons.myLocation fontSize="small" />}
            children={<LocationFormField />}
          />
          <SwipeDrawer
            label={t('search.types count', {
              ns: 'common',
              count: (searchParams.get('type') ?? '')
                .split('+')
                .filter((type) => validTypes.has(type as SpotTypeEnum)).length,
            })}
            icon={<theme.icons.restaurant fontSize="small" />}
            children={<TypeFormField />}
          />
          <FiltersAdvanced />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <LocationFormField />
      <TypeFormField />
      <NameFormField />
      <FiltersAdvanced />
    </Stack>
  );
};

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[800],
  borderRadius: 3,
  ...theme.applyStyles('dark', {
    backgroundColor: grey[900],
  }),
}));

interface SwipeDrawer {
  children?: React.ReactElement<
    Partial<{ open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }>
  >;
  icon?: React.ReactNode;
  label: string;
}

function SwipeDrawer(props: SwipeDrawer) {
  const { label, icon = null, children } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Chip icon={icon} color="uncolored" label={label} onClick={handleOpen} />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
        }}
      >
        <Stack spacing={4} px={4} pt={2} pb={6} alignItems="center">
          <Puller />
          {React.isValidElement(children) ? React.cloneElement(children, { open, setOpen }) : children}
        </Stack>
      </SwipeableDrawer>
    </>
  );
}
