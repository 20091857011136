import _ from "lodash";

import account from './account';
import authentication from "./authentication";
import booking from './booking';
import cta from './cta';
import common from './common';
import country from './country';
import conversation from './conversation';
import event from './event';
import field from './field';
import notification from './notification';
import pwa from './pwa';
import spot from './spot';

const translations = _.merge(
  account,
  authentication,
  booking,
  cta,
  common,
  country,
  conversation,
  event,
  field,
  notification,
  pwa,
  spot,
);

export default translations;
