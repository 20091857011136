import { gql } from "@apollo/client";

export const IS_EMAIL_AVAILABLE = gql`
  query isEmailAvailable($email: String!) {
    isEmailAvailable(email: $email)
  }
`;

export const VERIFY_PASSWORD = gql`
  mutation isPasswordValid($password: String!) {
    isPasswordValid(password: $password)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!) {
    changePassword(password: $password)
  }
`;

export const SWITCH_ACCOUNT = gql`
  mutation SwitchAccount($id: ID!) {
    switchAccount(id: $id)
  }
`;

/**
 * @description Get the public VAPID key for push notifications
 */
export const GET_PUBLIC_VAPID_KEY = gql`
  query GetPublicVapidKey {
    getPublicVapidKey
  }
`;

export const SAVE_PUSH_SUBSCRIPTION = gql`
  mutation SavePushSubscription($input: PushSubscriptionInput!) {
    savePushSubscription(input: $input)
  }
`;


export const GET_SESSION = gql`
  query GetSession {
    session {
      id
      ssoProvider
      email
      emailTokenSentAt
      firstname
      lastname
      gender
      birthdate
      mobilePhone
      preferences {
        language
        theme
      }
      isActive
      isVerified
      isDelete
      createdAt
      updatedAt
      deletedAt
      accounts {
        __typename
        ... on Consumer {
          id
          isCurrent
          name
          slug
          avatar {
            id
            url
            fileId
            fileName
            size
            mimeType
            createdAt
          }
          isActive
          isVerified
          deletedAt
          isPrivate
        }
        ... on Support {
          id
          isCurrent
          name
          slug
          avatar {
            id
            url
            fileId
            fileName
            size
            mimeType
            createdAt
          }
          isActive
          isVerified
          deletedAt
        }
        ... on Organizer {
          id
          isCurrent
          name
          slug
          avatar {
            id
            url
            fileId
            fileName
            size
            mimeType
            createdAt
          }
          isActive
          isVerified
          deletedAt
        }
        ... on Maker {
          id
          isCurrent
          name
          slug
          avatar {
            id
            url
            fileId
            fileName
            size
            mimeType
            createdAt
          }
          isActive
          isVerified
          deletedAt
        }
        ... on Spot {
          id
          isCurrent
          name
          slug
          avatar {
            id
            url
            fileId
            fileName
            size
            mimeType
            createdAt
          }
          isActive
          isVerified
          deletedAt
          address {
            id
            timezone
          }
          notifications {
            id
            standby {
              all {
                email
                push
              }
            }
            request {
              message {
                email
                push
              }
              mention {
                email
                push
              }
            }
            booking {
              new {
                email
                push
              }
              edit {
                email
                push
              }
              late {
                email
                push
              }
              cancel {
                email
                push
              }
            }
            event {
              invitation {
                email
                push
              }
              full {
                email
                push
              }
              upcoming {
                email
                push
              }
              cancel {
                email
                push
              }
            }
            feedback {
              new {
                email
                push
              }
            }
          }
          opening {
            id
            dayOfWeek
            openTime
            closingTime
            breakIn
            breakOut
            updatedAt
          }
          closing {
            id
            reason
            description
            from
            to
            updatedAt
          }
          atmosphere {
            id
            calm
            lively
            dancing
            updatedAt
          }
          bringable {
            id
            dogs
            food
            drinks
            birthdayCake
            updatedAt
          }
          configuration {
            id
            defaultImage
            primaryType
            secondaryType
            tertiaryType
            pricing
            closeSpaces
            musicalGenre
            capacity
            entryPrice
            updatedAt
          }
          equipment {
            id
            amphitheater
            auditorium
            sono
            wifi
            stage
            video
            locker
            terrace
            toilets
            prmAccess
            dancefloor
            microphone
            prmToilets
            smokingRoom
            parking
            changingTable
            updatedAt
          }
          highlight {
            id
            mountainView
            oceanView
            rooftop
            seaView
            sunset
            sunrise
            updatedAt
          }
          payment {
            id
            amex
            cash
            checks
            blueCard
            mealTickets
            updatedAt
          }
          service {
            id
            dj
            karaoke
            ownMusic
            liveMusic
            entertainmentShow
            takeout
            updatedAt
          }
          suitable {
            id
            bachelor
            birthday
            farewell
            teamMeal
            wendding
            afterwork
            exposition
            cocktailPro
            fashionShow
            businessMeal
            companyEvent
            friendsDrink
            studentParty
            productLaunch
            kids
            watchSport
            telework
            veggie
            vegan
            updatedAt
          }
          type {
            id
            amusementPark
            aquarium
            artGallery
            bar
            cafe
            campground
            concertHall
            casino
            departmentStore
            nightClub
            park
            restaurant
            sportClub
            stadium
            updatedAt
          }
        }
      }
    }
  }
`;

export const LOGIN_USER = gql`
  mutation login($input: LoginInput!) {
    login(input: $input)
  }
`;

export const REFRESH_TOKENS = gql`
  mutation refreshTokens($refreshToken: String!) {
    refreshTokens(refreshToken: $refreshToken) {
      access
      refresh
    }
  }
`;

export const LOGIN_USER_SSO = gql`
  mutation loginSSO($input: LoginSSOInput!) {
    loginSSO(input: $input) {
      __typename
      ... on Tokens {
        access
        refresh
      }
      ... on ProfileSSO {
        email
        firstname
        lastname
        avatar {
          id
          url
          fileId
          fileName
          size
          mimeType
          createdAt
        }
      }
    }
  }
`;

export const QET_USER_BY_EMAIL_TOKEN = gql`
  query GetUserByEmailToken($token: String!) {
    userByEmailToken(token: $token) {
      id
      isVerified
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      id
      isVerified
      isActive
      updatedAt
    }
  }
`;

export const RESEND_ACTIVATION_MAIL = gql`
  mutation reSendActivationMail {
    reSendActivationMail {
      id
      emailTokenSentAt
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;
