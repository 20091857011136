import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

import { APP_TITLE } from '@utils';
import { useSession } from '@hooks';
import { useGetSpotMetaLazyQuery, useGetEventMetaLazyQuery, useGetBookingMetaLazyQuery } from '@graphql';

export function Meta() {
  const { account } = useSession();
  const [title, setTitle] = React.useState<string | undefined>(APP_TITLE);
  const [description, setDescription] = React.useState<string | undefined>('Events • Spots');
  const [image, setImage] = React.useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();
  const { t, i18n } = useTranslation('common');
  const locale = `${i18n.language}_${i18n.language.toUpperCase()}`;
  const PAGE_URL = `${window.location.origin}${location.pathname}${location.search}`;

  const [getSpot] = useGetSpotMetaLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const [getEvent] = useGetEventMetaLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const [getBooking] = useGetBookingMetaLazyQuery({
    fetchPolicy: 'cache-first',
  });

  React.useEffect(() => {
    const { pathname } = location;

    if (pathname === '/spots') {
      const slug = searchParams.get('s');

      if (!slug) {
        // HOME SPOTS
        setTitle(`Spots | ${APP_TITLE}`);
        setDescription('Spots ' + t('word.to discover'));
        setImage(undefined);
      } else {
        // OPENED HOME SPOTS
        getSpot({
          variables: { slug },
          onCompleted({ spot }) {
            setTitle(`${spot.name} | Spot | ${APP_TITLE}`);
            setDescription('Spot ' + t('word.to discover'));
            setImage(spot.covers[0]?.url);
          },
        });
      }
    } else if (pathname === '/events') {
      const id = searchParams.get('e');

      if (!id) {
        // HOME EVENTS
        setTitle(`Events | ${APP_TITLE}`);
        setDescription('Events ' + t('word.to discover'));
        setImage(undefined);
      } else {
        // OPENED HOME EVENT
        getEvent({
          variables: { id },
          onCompleted({ event }) {
            setTitle(`${event.title} | Event | ${APP_TITLE}`);
            setDescription('Event ' + t('word.to discover'));
            setImage(event.covers[0]?.url);
          },
        });
      }
    } else if (pathname === '/search') {
      // HOME SEARCH
      const search = searchParams.get('search');
      setTitle(`${t('word.Search')} ${search ?? ''} | ${APP_TITLE}`);
      setDescription(search + ' ' + t('word.to discover'));
      setImage(undefined);

    } else if (pathname.startsWith('/search/spot/')) {
      // OPENED SEARCH SPOT
      const { slug = '' } = params;
      getSpot({
        variables: { slug },
        onCompleted({ spot }) {
          setTitle(`${spot.name} | Spot | ${APP_TITLE}`);
          setDescription('Spot ' + t('word.to discover'));
          setImage(spot.covers[0]?.url);
        },
      });
    } else if (pathname.startsWith('/search/event/')) {
      // OPENED SEARCH EVENT
      const { id = '' } = params;
      getEvent({
        variables: { id },
        onCompleted({ event }) {
          setTitle(`${event.title} | Booking | ${APP_TITLE}`);
          setDescription('Event ' + t('word.to discover'));
          setImage(event.covers[0]?.url);
        },
      });
    } else if (params.bookingId) {
      getBooking({
        variables: { id: params.bookingId },
        onCompleted({ booking }) {
          const date = moment(booking.startTime).format('LLLT');
          let title = 'Booking';

          if (account?.__typename === 'Consumer') {
            title = t(`meta title.consumer`, { ns: 'booking', date, spot: booking.spot.name });
          } else if (account?.__typename === 'Spot') {
            let firstname = '';
            if (booking.client.__typename === 'BookingClient') {
              firstname = booking.client.firstname;
            } else if (booking.client.__typename === 'Consumer') {
              firstname = booking.client.user.firstname;
            }
            title = t(`meta title.spot`, { ns: 'booking', date, firstname });
          }

          console.log(`[{title}]:`, title);

          setTitle(`${title} | ${APP_TITLE}`);
          setDescription('Booking');
          setImage(undefined);
        },
      });
    } else if (pathname.startsWith('/me/calendar') || pathname.startsWith('/me/table')) {
      // AGENDA
      setTitle(`${t('word.My agenda')} | ${APP_TITLE}`);
      setDescription('Events • Spots');
      setImage(undefined);
    } else {
      // DEFAULT
      setTitle(APP_TITLE);
      setDescription('Events • Spots');
      setImage(undefined);
    }
  }, [location, searchParams]);

  return (
    <Helmet>
      <title>{title}</title>

      {/* BASE OPEN GRAPH */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={PAGE_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />

      {/* TWITTER OPEN GRAPH */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={PAGE_URL} />
    </Helmet>
  );
}
