import moment, { Moment } from 'moment';
import { useTheme, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GetSpoEventsQuery } from '@graphql';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type Event = GetSpoEventsQuery['spot']['events'][0];

interface DaySlotProps {
  day: string;
  events: Event[];
  loading: boolean;
  selectedDate: Moment | null;
}

export default function DaySlot(props: DaySlotProps) {
  const { day, events, loading, selectedDate } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dayDate = selectedDate?.clone().isoWeekday(day);
  const location = useLocation();
  const { t } = useTranslation('spot');

  const textColor = theme.palette.grey[events.length ? 300 : 600];

  return (
    <Paper
      elevation={events.length ? 5 : 0}
      minheight={100}
      sx={{
        p: 1,
        backgroundColor: events.length
          ? theme.palette.background.paper
          : alpha(theme.palette.background.paper, 0.3),
      }}
    >
      <Paper
        elevation={5}
        sx={{
          p: isMobile ? theme.spacing(1, 2) : theme.spacing(2, 3),
          mb: 2,
          backgroundColor: alpha(theme.palette.grey[500], events.length ? 0.2 : 0.1),
        }}
      >
        <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography
              color={textColor}
              variant="body2"
              fontSize={{ sm: '.8rem', md: '1rem' }}
              fontWeight="bold"
            >
              {day}
            </Typography>
            {moment(dayDate).isSame(moment(), 'day') && (
              <Chip
                label={t('word.today', { ns: 'common' })}
                sx={{
                  ...(isMobile && { fontSize: '0.6rem' }),
                }}
                variant="outlined"
                size="small"
              />
            )}
          </Stack>
          <Typography color={textColor} variant="body2" fontSize={{ sm: '.8rem', md: '1rem' }}>
            {dayDate?.format('DD MMMM YYYY')}
          </Typography>
        </Stack>
      </Paper>

      <Stack alignItems="flex-start" spacing={2}>
        {loading ? (
          <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
          </Stack>
        ) : events.length > 0 ? (
          events.map((event) => {
            const isSearch = location.pathname.startsWith('/search/');
            const to = {
              pathname: isSearch ? `/search/event/${event.id}` : location.pathname,
              search: isSearch ? location.search : `?e=${event.id}`,
            };

            return (
              <Link key={event.id} to={to} preventScrollReset={true} style={{ width: '100%' }}>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                  <Box
                    component="img"
                    sx={{
                      borderRadius: theme.borderRadius,
                      height: 200,
                      width: isMobile ? '100%' : 200,
                      objectFit: 'cover',
                    }}
                    alt={event.title + ' cover'}
                    src={event.covers[0]?.url || ''}
                  />
                  <Stack spacing={2} ml={2} width="100%">
                    <Typography variant="h5" fontWeight="bold">
                      {event.title}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2">Horaires:</Typography>
                      <Typography variant="body2">
                        de {moment(event.from).format('HH:mm')} à {moment(event.to).format('HH:mm')}
                      </Typography>
                    </Stack>
                    <Stack direction="row" width="100%" spacing={2}>
                      {event.tickets
                        .map((ticket) => (
                          <Stack
                            component="div"
                            key={ticket.id}
                            p={2}
                            width={isMobile ? '100%' : '20%'}
                            alignItems="center"
                            sx={{
                              borderRadius: theme.borderRadius,
                              backgroundColor: theme.palette.grey[900],
                              border: '1px dashed rgba(255, 255, 255, 0.5)',
                            }}
                          >
                            <Typography variant="caption">Reste 10 billets</Typography>
                            <Typography variant="body1" fontWeight="bold">
                              {ticket.label}
                            </Typography>
                            <Typography variant="body2">à partir de {ticket.price} €</Typography>
                          </Stack>
                        ))
                        .reduce((acc, el, i, arr) => {
                          if (i < 1) return [...acc, el];
                          if (i === 3)
                            return [
                              ...acc,
                              <Stack
                                component="div"
                                key={'event-overload'}
                                p={2}
                                width="20%"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  borderRadius: theme.borderRadius,
                                  backgroundColor: theme.palette.grey[900],
                                  border: '1px dashed rgba(255, 255, 255, 0.5)',
                                }}
                              >
                                <Typography variant="h6" fontWeight="bold">
                                  +{arr.length - 1}
                                </Typography>
                                <Typography variant="body2">autres billets</Typography>
                              </Stack>,
                            ];
                          return acc;
                        }, [])}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      {event.tags.map((tag) => (
                        <Chip key={tag.id} label={'#' + tag.label} color="uncolored" />
                      ))}
                    </Stack>
                    {event.category?.name && (
                      <Stack direction="row" spacing={2}>
                        {t(`category.${event.category?.name}`, { ns: 'event' })}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Link>
            );
          })
        ) : (
          <Typography
            variant="body2"
            width="100%"
            align="center"
            fontStyle="italic"
            color={theme.palette.grey[600]}
          >
            {t('event.No event')}
          </Typography>
        )}
      </Stack>
    </Paper>
  );
}
