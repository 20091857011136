import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Device from 'react-device-detect';

import { useSession } from '@hooks';

const PWA_INSTALL_SEEN = 'PWA_INSTALL_SEEN';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

export const InstallPWA = () => {
  const [isInstalled, setIsInstalled] = React.useState(false);
  const [deferredPrompt, setDeferredPrompt] = React.useState<BeforeInstallPromptEvent | null>(null);
  const [open, setOpen] = React.useState(false);
  const { user } = useSession();
  const { t } = useTranslation('pwa');
  const isInstallable = Device.isDesktop || !user;

  React.useEffect(() => {
    if (!isInstallable) {
      return;
    }

    // Defer the prompt event
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as BeforeInstallPromptEvent);
      return false;
    };

    const checkInstalled = () => {
      const isStandalone =
        window.matchMedia('(display-mode: standalone)').matches ||
        (window.navigator as any).standalone === true;

      if (!isInstalled && !isStandalone) {
        setIsInstalled(isStandalone);
      }

      return isStandalone;
    };

    navigator.serviceWorker.ready.then((registration) => {
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.addEventListener('appinstalled', checkInstalled);
      checkInstalled();
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', checkInstalled);
    };
  }, []);

  React.useEffect(() => {
    if (!isInstallable) {
      return;
    }

    const lastSeen = localStorage.getItem(PWA_INSTALL_SEEN);
    // check if seen before 24h
    const isDisplayable = !lastSeen || moment().diff(moment(lastSeen), 'hours') > 24;

    if (!isInstalled && deferredPrompt && isDisplayable) {
      handleOpen();
      localStorage.setItem(PWA_INSTALL_SEEN, new Date().toISOString());
    }
  }, [isInstalled, deferredPrompt]);

  const handleInstall = () => {
    if (!isInstallable) {
      return;
    }

    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        setDeferredPrompt(null);
      });
    }

    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    // desactivate escape key & backdrop click
    return;
  };

  if (!isInstallable) return null;

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>{t('install.Continue with the app?')}</DialogTitle>
      <DialogContent>
        <Typography varian="body2">
          {t('install.For an enriched experience, you can continue with the app')}
        </Typography>
        {Device.isSafari && (
          <Typography varian="body2">
            {t('install.From Safari, click on the Share icon then select Add to Home Screen.')}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Stack width="100%" spacing={2}>
          <Button fullWidth size="large" color="success" variant="contained" onClick={handleInstall}>
            {Device.isSafari ? 'ok' : t('install', { ns: 'cta' })}
          </Button>
          <Button fullWidth size="small" color="uncolored" variant="outlined" onClick={handleClose}>
            {t('continue on browser', { ns: 'cta' })}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

function getPWADisplayMode() {
  if (document.referrer.startsWith('android-app://')) return 'twa';
  if (window.matchMedia('(display-mode: browser)').matches) return 'browser';
  if (window.matchMedia('(display-mode: standalone)').matches) return 'standalone';
  if (window.matchMedia('(display-mode: minimal-ui)').matches) return 'minimal-ui';
  if (window.matchMedia('(display-mode: fullscreen)').matches) return 'fullscreen';
  if (window.matchMedia('(display-mode: window-controls-overlay)').matches) return 'window-controls-overlay';

  return 'unknown';
}
