import * as helpers from "./helpers";
import _ from 'lodash';

export const initFilters = (searchParams: URLSearchParams, isLoggedIn: boolean) => {
  const searchKeyValues = Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
    if (!isLoggedIn && !baseFilters.has(key)) {
      return acc;
    }

    let baseValue = value as helpers.AllowedValues;

    if (value === 'true') {
      baseValue = true;
    } else if (value === 'false') {
      baseValue = false;
    } else if (!Number.isNaN(Number(value))) {
      baseValue = Number(value);
    } else {
      baseValue = null;
    }

    acc[key] = baseValue;
    return acc;
  }, {} as helpers.FormValues);

  return helpers.populateValues({ ...advancedFilters }, searchKeyValues);
}

export const baseFilters = new Set(['search', 'page', 'name', 'location', 'type']);

const advancedFilters = {
  // Types
  type: null,
  // Atomsphere
  calm: null,
  lively: null,
  dancing: null,
  // Type
  amusementPark: null,
  aquarium: null,
  artGallery: null,
  bar: null,
  cafe: null,
  campground: null,
  concertHall: null,
  casino: null,
  departmentStore: null,
  nightClub: null,
  park: null,
  restaurant: null,
  sportClub: null,
  stadium: null,

  // Advanced
  // Bringable
  dogs: null,
  food: null,
  drinks: null,
  birthdayCake: null,
  // Configuration
  minCapacity: null,
  maxCapacity: null,
  minCloseSpaces: null,
  maxCloseSpaces: null,
  // Equipment
  amphitheater: null,
  auditorium: null,
  sono: null,
  wifi: null,
  stage: null,
  video: null,
  locker: null,
  terrace: null,
  toilets: null,
  prmAccess: null,
  dancefloor: null,
  microphone: null,
  prmToilets: null,
  smokingRoom: null,
  parking: null,
  changingTable: null,
  // Highlight
  mountainView: null,
  oceanView: null,
  rooftop: null,
  seaView: null,
  sunset: null,
  sunrise: null,
  // Payment
  amex: null,
  cash: null,
  checks: null,
  blueCard: null,
  mealTickets: null,
  // Service
  dj: null,
  karaoke: null,
  ownMusic: null,
  liveMusic: null,
  entertainmentShow: null,
  takeout: null,
  // Suitable
  bachelor: null,
  birthday: null,
  farewell: null,
  teamMeal: null,
  wendding: null,
  afterwork: null,
  exposition: null,
  cocktailPro: null,
  fashionShow: null,
  businessMeal: null,
  companyEvent: null,
  friendsDrink: null,
  studentParty: null,
  productLaunch: null,
  kids: null,
  watchSport: null,
  telework: null,
  veggie: null,
  vegan: null,
};

export function initAdvancedValues() {
  return { ...advancedFilters };
};

export default function initValues(type?: 'name' | 'location' | 'type' | 'advanced') {
  switch (type) {
    case 'name':
      return { name: '' };
    case 'location':
      return { location: '' };
    case 'type':
      return { type: '' };
    case 'advanced':
      return { ...advancedFilters };
    default:
      return { ...{ name: '', location: '', type: '' }, ...advancedFilters, };
  }
};