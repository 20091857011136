import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface ClaimAccordionProps {}

export default function ClaimAccordion(props: ClaimAccordionProps) {
  const theme = useTheme();
  const { t } = useTranslation('cta');

  return (
    <Accordion sx={{ width: '100%', backgroundColor: theme.palette.background.default }}>
      <AccordionSummary
        expandIcon={<theme.icons.expandMore sx={{ color: 'white' }} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-end" width="100%">
          <Typography component="span" sx={{ flexShrink: 0 }}>
            {t('claim.Good to know', { ns: 'spot' })}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t('claim.the book button', { ns: 'spot' })}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={3} justifyContent="center" alignItems="center" width="100%">
          <Paper elevation={3} sx={{ width: '100%' }}>
            <Stack spacing={2} p={3} justifyContent="center" alignItems="flex-start" width="100%">
              <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                <Stack spacing={1} direction="row" alignItems="center">
                  <theme.icons.check color="success" />
                  <Typography variant="subtitle1">{t('claim.Bookable', { ns: 'spot' })}</Typography>
                </Stack>
                <Button variant="contained" color="primary" size="small">
                  {t('book')}
                </Button>
              </Stack>
              <Divider width="100%" sx={{ backgroundColor: theme.palette.grey[500] }} />
              <Typography variant="body2">
                {t('claim.The spot is claimed and accepts reservations', { ns: 'spot' })}
              </Typography>
            </Stack>
          </Paper>

          <Paper elevation={3} sx={{ width: '100%' }}>
            <Stack spacing={2} p={3} justifyContent="center" alignItems="flex-start" width="100%">
              <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                <Stack spacing={1} direction="row" alignItems="center">
                  <theme.icons.close color="error" />
                  <Typography variant="subtitle1">{t('claim.Bookable', { ns: 'spot' })}</Typography>
                </Stack>
                <Button variant="contained" color="primary" size="small" disabled>
                  {t('book')}
                </Button>
              </Stack>
              <Divider width="100%" sx={{ backgroundColor: theme.palette.grey[500] }} />
              <Typography variant="body2">
                {t('claim.The spot is claimed but does not accept reservations', { ns: 'spot' })}
              </Typography>
            </Stack>
          </Paper>

          <Paper elevation={3} sx={{ width: '100%' }}>
            <Stack spacing={2} p={3} justifyContent="center" alignItems="flex-start" width="100%">
              <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                <Stack spacing={1} direction="row" alignItems="center">
                  <theme.icons.close color="error" />
                  <Typography variant="subtitle1">{t('claim.Not bookable', { ns: 'spot' })}</Typography>
                </Stack>
                <Button variant="outlined" color="uncolored" size="small">
                  {t('book')}
                </Button>
              </Stack>
              <Divider width="100%" sx={{ backgroundColor: theme.palette.grey[500] }} />
              <Typography variant="body2">
                {t('claim.The spot is not yet claimed', { ns: 'spot' })}
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
