import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AlertTitle from '@mui/material/AlertTitle';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import * as Device from 'react-device-detect';

import { GetSpotDetailedQuery } from '@graphql';
import ClaimAccordion from './ClaimAccordion';
import ClaimForm from './ClaimForm';

interface ClaimButtonProps {
  spot: GetSpotDetailedQuery['spot'];
}

export default function ClaimButton(props: ClaimButtonProps) {
  const { spot } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation('cta');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Function to generate the share link according to the application
  const shareLink = (platform: string) => {
    let incentive = 'Salut !\n\n';
    incentive += `Ton spot ${spot.name} a été référencé sur Festayre 🥳\n\n`;
    incentive +=
      'Tu peux le revendiquer avec le lien qui suit et ensuite en cliquant sur le bouton "réserver" et enfin sur le bouton "revendiquer"\n\n';

    const url = encodeURIComponent(`https://alpha.festayre.fr/spots?s=${spot.slug}`);
    const text = encodeURIComponent(incentive);

    let link = '';

    switch (platform) {
      case 'whatsapp':
        link = `https://wa.me/?text=${text} ${url}`;
        break;
      case 'sms':
        // 'sms:' avec `&body` est recommandé pour iOS et `?body` pour Android
        link = /iPhone|iPad|iPod/i.test(navigator.userAgent)
          ? `sms:&body=${text} ${url}`
          : `sms:?body=${text} ${url}`;
        break;
      case 'email':
        const subject = encodeURIComponent(`Revendique ton spot ${spot.name} sur Festayre !`);
        const body = encodeURIComponent(`${incentive} https://alpha.festayre.fr/spots?s=${spot.slug}`);
        link = `mailto:?subject=${subject}&body=${body}`;
        break;
    }

    window.location.href = link;
  };

  if (!spot) {
    return null;
  }

  return (
    <React.Fragment>
      <Button variant="outlined" color="uncolored" fullWidth onClick={handleOpen}>
        {t('book')}
      </Button>
      <Dialog
        fullScreen={Device.isMobile}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        disableEnforceFocus
        disableRestoreFocus
        maxWidth="sm"
      >
        <AppBar sx={{ position: 'relative', backgroundColor: theme.palette.background.default }}>
          <Toolbar width="100%" sx={{ justifyContent: 'space-between' }}>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {t('word.Book', { ns: 'common' })}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Stack p={3} spacing={5} justifyContent="center" alignItems="center" width="100%">
          <Stack spacing={5} justifyContent="center" alignItems="center" width="100%">
            <Alert icon={false} severity="warning">
              <AlertTitle>{spot.name}</AlertTitle>
              {t(
                'claim.This spot has not yet been claimed, so it is not yet possible to make a reservation there.',
                { ns: 'spot' }
              )}
            </Alert>
            <Stack spacing={3} justifyContent="center" alignItems="center" width="100%">
              <Paper sx={{ backgroundColor: theme.palette.background.default, width: '100%' }}>
                <Stack p={3} spacing={2} justifyContent="center" alignItems="center">
                  <Typography variant="subtitle1" textAlign="center">
                    {t('claim.Are you the owner of this spot?', { ns: 'spot' })}
                  </Typography>
                  <Divider flexItem sx={{ backgroundColor: theme.palette.grey[500] }} />
                  <Typography variant="body2" textAlign="center">
                    {t('claim.You can claim it by clicking the button below.', { ns: 'spot' })}
                  </Typography>
                  <ClaimForm spot={spot} handleClose={handleClose} />
                </Stack>
              </Paper>
              <Paper sx={{ backgroundColor: theme.palette.background.default, width: '100%' }}>
                <Stack p={3} spacing={2} justifyContent="center" alignItems="center">
                  <Typography variant="subtitle1" textAlign="center">
                    {t('claim.Do you know the owner of this spot?', { ns: 'spot' })}
                  </Typography>
                  <Divider flexItem sx={{ backgroundColor: theme.palette.grey[500] }} />
                  <Typography variant="body2" textAlign="center">
                    {t('claim.You can invite him to claim his spot by sending him a', { ns: 'spot' })}
                  </Typography>
                  <Stack spacing={1} direction={Device.isMobile ? 'column' : 'row'}>
                    {Device.isMobile && (
                      <Button
                        fullWidth
                        startIcon={<theme.icons.sms />}
                        variant="contained"
                        color="uncolored"
                        onClick={() => shareLink('sms')}
                      >
                        {t('sms')}
                      </Button>
                    )}
                    {Device.isMobile && (
                      <Button
                        fullWidth
                        startIcon={<theme.icons.whatsapp />}
                        variant="contained"
                        color="uncolored"
                        onClick={() => shareLink('whatsapp')}
                      >
                        {t('whatsapp')}
                      </Button>
                    )}
                    <Button
                      fullWidth
                      startIcon={<theme.icons.send />}
                      variant="contained"
                      color="uncolored"
                      onClick={() => shareLink('email')}
                    >
                      email
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </Stack>
          </Stack>

          <ClaimAccordion />
        </Stack>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="uncolored">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
