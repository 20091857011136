import * as React from 'react';
import { useTheme, alpha } from '@mui/material';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import { useSearchParams, Outlet } from 'react-router-dom';
import * as Device from 'react-device-detect';

import { SearchResults } from './SearchResults';
import { SearchEventForm, SearchSpotForm } from './form-fields';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: React.CSSProperties;
}

function samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

const SearchTabPanel = React.memo((props: TabPanelProps) => {
  const { children, value, index, style, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`search-tabpanel-${index}`}
      aria-labelledby={`search-tab-${index}`}
      style={{ ...style }}
      {...other}
    >
      {value === index && children}
    </div>
  );
});

interface LinkTabProps {
  label?: string;
  href: string;
  selected?: boolean;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      aria-current={props.selected && 'page'}
      {...props}
    />
  );
}

interface SearchCardProps {
  children: React.ReactNode;
}

export const SearchCard = (props: SearchCardProps) => {
  const { children } = props;
  const theme = useTheme();

  return <Stack sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 1 }}>{children}</Stack>;
};

export const SearchPage = React.memo(() => {
  const [tab, setTab] = React.useState(0);
  const theme = useTheme();
  const { isLandscape } = Device.useMobileOrientation();
  let [searchParams, setSearchParams] = useSearchParams();

  const validTabs: { [key: number]: string } = {
    0: 'spots',
    1: 'events',
  };

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' &&
        samePageLinkNavigation(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>))
    ) {
      if (Object.keys(validTabs).includes(newTab.toString())) {
        const location = searchParams.get('location');

        setSearchParams(
          { search: validTabs[newTab], page: '1', ...(location && { location }) },
          {
            state: { from: 'searchPage-handleChangeTab' },
            replace: true,
            flushSync: true,
            viewTransition: true,
          }
        );
      }
    }
  };

  // set tab based on search param
  React.useEffect(() => {
    const search = searchParams.get('search');

    if (search) {
      const idx = Object.values(validTabs).indexOf(search);

      if (idx !== -1) {
        setTab(idx);
      } else {
        setSearchParams(
          { search: 'spots', page: '1' },
          { state: { from: 'searchPage-1' }, replace: true, viewTransition: true }
        );
      }
    } else {
      setSearchParams(
        { search: 'spots', page: '1' },
        { state: { from: 'searchPage-fallback' }, replace: true, viewTransition: true }
      );
    }
  }, [searchParams]);

  if (Device.isDesktop) {
    return (
      <Stack
        direction="row"
        width="100vw"
        height={`calc(100vh - 64px)`} // 64px for appBar
        sx={{ overflowY: 'hidden' }}
      >
        <Stack width="100%" minHeight="100%" sx={{ overflowY: 'scroll' }}>
          <SearchResults search={searchParams.get('search')} />
        </Stack>
        <Stack
          width={500}
          height="100%"
          sx={{
            backgroundColor: theme.palette.background.default,
            boxShadow: '0 10px 20px rgba(0, 0, 0, .5)',
            overflowY: 'scroll',
          }}
        >
          <MuiTabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="search tabs"
            centered
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
          >
            <LinkTab label="Spots" href="/search?search=spots" selected={tab === 0} />
            <LinkTab label="Events" href="/search?search=events" selected={tab === 1} />
          </MuiTabs>
          <SearchTabPanel value={tab} index={0} style={{ padding: 10, height: '100vh' }}>
            <SearchSpotForm />
          </SearchTabPanel>
          <SearchTabPanel value={tab} index={1} style={{ padding: 10, height: '100vh' }}>
            <SearchEventForm />
          </SearchTabPanel>
        </Stack>
        <Outlet />
      </Stack>
    );
  }

  if (isLandscape) {
    return (
      <Stack height="100vh" pt={9} px={1}>
        <Stack spacing={2}>
          <SearchCard>
            <MuiTabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="search tabs"
              centered
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
            >
              <LinkTab label="Spots" href="/search?search=spots" selected={tab === 0} />
              <LinkTab label="Events" href="/search?search=events" selected={tab === 1} />
            </MuiTabs>
            <SearchTabPanel value={tab} index={0} style={{ padding: 10, minHeight: '100%' }}>
              <SearchSpotForm />
            </SearchTabPanel>
            <SearchTabPanel value={tab} index={1} style={{ padding: 10, minHeight: '100%' }}>
              <SearchEventForm />
            </SearchTabPanel>
          </SearchCard>
          <SearchResults search={searchParams.get('search')} />
        </Stack>
        <Outlet />
      </Stack>
    );
  }

  return (
    <>
      <AppBar
        mt={9}
        position="sticky"
        sx={{
          zIndex: Device.isMobileOnly ? theme.zIndex.appBar : theme.zIndex.drawer + 1,
          backgroundColor: alpha(theme.palette.background.paper, 0.9),
          backdropFilter: 'blur(20px)',
        }}
      >
        <MuiTabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="search tabs"
          centered
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
        >
          <LinkTab label="Spots" href="/search?search=spots" selected={tab === 0} />
          <LinkTab label="Events" href="/search?search=events" selected={tab === 1} />
        </MuiTabs>
        <SearchTabPanel value={tab} index={0} style={{ padding: 10, minHeight: '100%' }}>
          <SearchSpotForm />
        </SearchTabPanel>
        <SearchTabPanel value={tab} index={1} style={{ padding: 10, minHeight: '100%' }}>
          <SearchEventForm />
        </SearchTabPanel>
      </AppBar>
      <Stack spacing={2} px={2} pt={2}>
        <SearchResults search={searchParams.get('search')} />
        <Outlet />
      </Stack>
    </>
  );
});
