import { Theme } from '@mui/material';

const useStyles = (theme: Theme, coverUrl?: string, isGray?: boolean) => ({
  highlight: {
    position: 'absolute',
    width: '100%',
    height: '95vh',
    top: 0,
    backgroundImage: `
    linear-gradient(
      to bottom,
      rgba(20, 20, 20, 0) 80%,
      rgba(20, 20, 20, 1) 100%
    ),
    url('${coverUrl?.replace('900', '1790')?.replace('500', '975')}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: isGray ? 'grayscale(100%)' : 'none',
  },
});

export default useStyles;
