import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { GetSpotDetailedQuery } from '@graphql';
import FollowButton from './buttons/FollowButton';
import BookingButton from './buttons/BookingButton';
import ClaimButton from './buttons/claim';
import WriteButton from '../../common/WriteButton';

interface SpotPreviewProps {
  spot: GetSpotDetailedQuery['spot'];
}

function IDCard(props: SpotPreviewProps) {
  const { spot } = props;
  const { account } = useSession();
  const { t } = useTranslation('spot');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const avatarScale = isMobile ? 75 : 100;

  const { avatar, name, events, addicts, configuration } = spot;
  const { primaryType, secondaryType, tertiaryType } = configuration;

  return (
    <>
      <Stack spacing={3} direction={isMobile ? 'column' : 'row'} width="100%">
        <Stack
          spacing={3}
          pt={isMobile ? 2 : 0}
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Avatar
            src={avatar?.url}
            alt={'avatar' + avatar?.url ? `-${name}` : ''}
            sx={{ width: avatarScale, height: avatarScale, bgcolor: theme.palette.background.default }}
          >
            <theme.icons.store
              sx={{ width: avatarScale, height: avatarScale, color: theme.palette.text.primary }}
            />
          </Avatar>
          <Stack spacing={3} direction="row" width="100%" justifyContent="space-evenly" pr={5}>
            <Stack alignItems="center">
              <Typography variant="body1" color="textPrimary" fontWeight="bold">
                {getCount(addicts.length)}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {`addict${addicts.length > 1 ? 's' : ''}`}
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="body1" color="textPrimary" fontWeight="bold">
                {getCount(events.length)}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {`event${events.length > 1 ? 's' : ''}`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={1} width="100%">
          {account?.__typename !== 'Spot' && (
            <>
              {spot.configuration.isClaimed ? <BookingButton spot={spot} /> : <ClaimButton spot={spot} />}
              <Stack spacing={1} direction="row" width="100%">
                <FollowButton spotId={spot.id} isFollowing={spot.isFollowing} />
                <WriteButton recipientId={spot.id} />
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <Stack width="100%">
        <Typography variant="h5" color="textPrimary">
          {name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {[primaryType, secondaryType, tertiaryType]
            .filter(Boolean)
            .map((type) => t(`types.${type}.label`))
            .join(' • ')}
        </Typography>
      </Stack>
    </>
  );
}

function getCount(number: number) {
  if (number < 1000) {
    return number;
  } else if (number < 1_000_000) {
    return `${Math.floor(number / 1000)}K`;
  } else if (number < 1_000_000_000) {
    return `${Math.floor(number / 1_000_000)}M`;
  } else if (number < 1_000_000_000_000) {
    return `${Math.floor(number / 1_000_000_000)}B`;
  }
}

export default IDCard;
