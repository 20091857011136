import * as React from 'react';

import { useGetPublicVapidKeyLazyQuery, useSavePushSubscriptionMutation } from '@graphql';
import { useSession } from '@hooks';

const isDev = import.meta.env?.DEV ?? false;

const usePushSubscription = () => {
  const [hasSubscribeError, setHasSubscribeError] = React.useState(false);
  const [hasSubscribe, setHasSubscribe] = React.useState(false);
  const { user } = useSession();

  const [getVapidkey] = useGetPublicVapidKeyLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const [savePushSubscription] = useSavePushSubscriptionMutation();

  const subscribeToPushNotifications = async () => {
    if (!user) {
      console.warn('User not logged.');
      return;
    }

    if (hasSubscribe || hasSubscribeError) {
      console.warn('hasSubscribe', hasSubscribe);
      console.warn('hasSubscribeError', hasSubscribeError);
      return;
    }

    if (!('serviceWorker' in navigator)) {
      console.warn('Service Workers not supported in this browser.');
      return;
    }

    const registration = await navigator.serviceWorker.ready;

    try {
      let subscription = await registration.pushManager.getSubscription();
      console.log(`[{subscription #1}]:`, subscription);

      if (!subscription) {
        const { data } = await getVapidkey();

        if (data?.getPublicVapidKey?.length) {
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: data?.getPublicVapidKey,
          });
          console.log(`[{subscription #2}]:`, subscription);
        } else {
          console.warn('No public VAPID key found.');
          return;
        }
      }

      if (subscription && subscription.endpoint && subscription !== undefined) {
        const input = {
          endpoint: subscription.endpoint,
          p256dh: subscription.toJSON().keys?.p256dh ?? '',
          auth: subscription.toJSON().keys?.auth || '',
        };

        savePushSubscription({
          variables: { input },
          onCompleted(data) {
            setHasSubscribe(true);
          },
        });
      }
    } catch (err) {
      setHasSubscribeError(true);
      console.error('Error subscribing:', err);
    }
  };

  return {
    subscribeToPushNotifications,
  };
};

export default usePushSubscription;
