import * as React from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useClaimSpotMutation, GetSpotDetailedQuery } from '@graphql';

interface ClaimFormProps {
  spot: GetSpotDetailedQuery['spot'];
  handleClose: () => void;
}

export default function ClaimForm(props: ClaimFormProps) {
  const { spot, handleClose } = props;
  const [isClaiming, setIsClaiming] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation('cta');

  React.useEffect(() => {
    if (!isClaiming) {
      formik.resetForm();
    }
  }, [isClaiming]);

  const [claimSpot] = useClaimSpotMutation();

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    siret: '',
    message: '',
  };

  const validationSchema = yup.object({
    firstname: yup.string().min(2).required(),
    lastname: yup.string().min(2).required(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(10)
      .max(10)
      .required(),
    siret: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(14)
      .max(14)
      .required(),
    message: yup.string(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      setLoading(true);
      claimSpot({
        variables: {
          id: spot?.id,
          input: values,
        },
        onCompleted() {
          setLoading(false);
          toast.success(t('spotClaim.toast.success', { ns: 'field' }));
          formik.resetForm();
          handleClose();
        },
        onError(error) {
          toast.error(t('spotClaim.toast.error', { ns: 'field' }));
        },
      });
    },
  });

  const handleClaim = () => {
    setIsClaiming(!isClaiming);
  };

  if (!spot) {
    return null;
  }

  return (
    <>
      <Button fullWidth variant="contained" color="uncolored" onClick={handleClaim}>
        {isClaiming ? t('cancel') : t('claim')}
      </Button>
      {isClaiming && (
        <Stack py={2} width="100%">
          <Divider />
        </Stack>
      )}
      {isClaiming && (
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Stack spacing={2} width="100%">
            <TextField
              fullWidth
              required
              {...formik.getFieldProps('firstname')}
              size="small"
              placeholder="ton prénom"
              label="prénom"
              variant="outlined"
              error={formik.touched.firstname && Boolean(formik.errors.firstname)}
              helperText={
                Boolean(formik.errors.firstname) ? (formik.errors.firstname as unknown as string) : ''
              }
            />
            <TextField
              fullWidth
              required
              {...formik.getFieldProps('lastname')}
              size="small"
              placeholder="ton nom"
              label="nom"
              variant="outlined"
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={
                Boolean(formik.errors.lastname) ? (formik.errors.lastname as unknown as string) : ''
              }
            />
            <TextField
              fullWidth
              required
              {...formik.getFieldProps('email')}
              type="email"
              size="small"
              placeholder="ton addresse email"
              label="email"
              variant="outlined"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={Boolean(formik.errors.email) ? (formik.errors.email as unknown as string) : ''}
            />
            <TextField
              fullWidth
              required
              {...formik.getFieldProps('phone')}
              type="tel"
              size="small"
              placeholder="ton numéro de téléphone"
              label="téléphone"
              variant="outlined"
              onChange={(e) => {
                formik.setFieldValue('phone', e.target.value.replace(/\D/g, ''));
              }}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={Boolean(formik.errors.phone) ? (formik.errors.phone as unknown as string) : ''}
            />
            <TextField
              fullWidth
              required
              {...formik.getFieldProps('siret')}
              size="small"
              placeholder="n° siret"
              label="siret"
              variant="outlined"
              onChange={(e) => {
                formik.setFieldValue('siret', e.target.value.replace(/\D/g, ''));
              }}
              error={formik.touched.siret && Boolean(formik.errors.siret)}
              helperText={Boolean(formik.errors.siret) ? (formik.errors.siret as unknown as string) : ''}
            />
            <TextField
              fullWidth
              multiline
              {...formik.getFieldProps('message')}
              rows={5}
              size="small"
              placeholder="message"
              label="message"
              variant="outlined"
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={Boolean(formik.errors.message) ? (formik.errors.message as unknown as string) : ''}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="success"
              loading={loading}
              disabled={!formik.dirty || !formik.isValid}
            >
              {t('send')}
            </Button>
          </Stack>
        </form>
      )}
    </>
  );
}
