import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer, Slide } from 'react-toastify';
import { ScrollRestoration } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import * as Device from 'react-device-detect';

import {
  EntityDialog as AccountEventDialog,
  BookingDialog,
  AuthenticationDialog,
  OAuthRegisterDialog,
  Meta,
} from '@components';
import { AppBar } from './app-bar';
import BottomNavigation from './BottomNavigation';
import AppLoader from './AppLoader';

export default function Layout() {
  return (
    <>
      <CssBaseline />
      <ScrollRestoration />
      <AppBar />
      <Box
        component="main"
        width="100vw"
        maxWidth="-webkit-fill-available"
        height={Device.isMobile ? '100vh' : 'calc(100vh - 64px)'} // 64px for appBar
        pb={Device.isMobileOnly ? 9 : 0}
      >
        <Outlet />
        <AppLoader />
        <AuthenticationDialog />
        <OAuthRegisterDialog />
        <BookingDialog />
        <AccountEventDialog type="consumer" navigation="query" />
        <AccountEventDialog type="event" navigation="query" />
        <AccountEventDialog type="spot" navigation="query" />
        <Meta />
      </Box>
      {Device.isMobileOnly ? <BottomNavigation /> : null}
      <ToastContainer
        style={{
          ...(import.meta.env.MODE === 'development' && { width: '500px' }),
        }}
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeButton={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="dark"
        transition={Slide}
      />
    </>
  );
}
