import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';

import App from './App';
import apolloClient from './apollo';
import { ShowPushNotification } from '@components';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

if ('serviceWorker' in navigator) {
  const swFile = import.meta.env.DEV ? '/dev-sw.js?dev-sw' : '/sw.js';

  navigator.serviceWorker
    .register(swFile, { type: 'module' })
    .then(async (registration) => {
      console.log('Service Worker registered successfully:', registration);

      // Listen to messages from the Service Worker
      navigator.serviceWorker.addEventListener('message', (event) => {
        import.meta.env.DEV && console.info('📩 Message recceived in SW:', event.data);
        ShowPushNotification(event.data?.data);
      });
    })
    .catch((error) => {
      console.error('Error while registring Service Worker', error);
    });
} else {
  console.error('Service Workers are not supported in this browser.');
}