import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';
import moment from 'moment';

import {
  useListHomeEventsQuery,
  ListHomeSpotsQuery,
  useListHomeSpotsQuery,
  ListHomeEventsQuery,
} from '@graphql';
import { truncateText } from '@utils';
import useStyles from './styles';
import { Insight, Extra } from './Incentives';
import { Item } from './types';

interface HighlightProps {
  type: 'spot' | 'event';
}

export function Highlight(props: HighlightProps) {
  const { type } = props;
  const [item, setItem] = React.useState<Item | null>(null);
  const { isPortrait } = Device.useMobileOrientation();
  const { pathname } = useLocation();
  const theme = useTheme();
  const styles = useStyles(theme, item?.cover, item?.isGray);
  const navigate = useNavigate();
  const { t } = useTranslation('spot');

  useListHomeEventsQuery({
    skip: type !== 'event',
    fetchPolicy: 'cache-and-network',
    onCompleted({ eventCategories }) {
      const item = getRandomEventItem(eventCategories);
      setItem(item);
    },
  });

  useListHomeSpotsQuery({
    fetchPolicy: 'cache-and-network',
    skip: type !== 'spot',
    onCompleted({ homeSpots }) {
      const item = getRandomSpotItem(homeSpots);
      setItem(item);
    },
  });

  function getRandomSpotItem(categories: ListHomeSpotsQuery['homeSpots']): Item {
    const category = getRandomItem(categories);
    const spot = getRandomItem(category.spots) as ListHomeSpotsQuery['homeSpots'][0]['spots'][0];
    const cover = getRandomItem(spot?.covers || []);

    // tmp allow spot with no cover for now
    if (!spot?.configuration) {
      return getRandomSpotItem(categories);
    }

    const types = [
      spot.configuration.primaryType,
      spot.configuration.secondaryType,
      spot.configuration.tertiaryType,
    ]
      .filter(Boolean)
      .map((type) => t(`types.${type}.label`, { ns: 'spot' }))
      .join(' • ');

    return {
      id: spot.id,
      title: spot.name,
      description: spot.description ?? '',
      city: spot.address.city,
      slug: spot.slug,
      cover: cover?.url || `/spot/${spot.configuration.primaryType}.jpg`,
      isGray: spot.covers.length === 0,
      insight: types,
    };
  }

  function getRandomEventItem(categories: ListHomeEventsQuery['eventCategories']) {
    const category = getRandomItem(categories);
    const event = getRandomItem(category.events);
    const cover = getRandomItem(event?.covers || []);

    if (!cover) {
      return getRandomEventItem(categories);
    }

    return {
      id: event.id,
      title: event.title,
      description: truncateText(event.description, 200),
      city: event.address?.city || '',
      insight: moment(event.from).format('dddd MMMM YYYY'),
      cover: cover.url,
    };
  }

  function getRandomItem(list: any[]) {
    return list[Math.floor(Math.random() * list.length)];
  }

  const handleOpen = () => {
    const path = type === 'spot' ? `s=${item?.slug}` : `e=${item?.id}`;
    navigate(`${pathname}?${path}`, { preventScrollReset: true });
  };

  if (Device.isMobileOnly && !isPortrait) {
    return null;
  }

  if (Device.isMobileOnly) {
    if (!item) {
      return (
        <Stack justifyContent="center" alignItems="center" width="100%" height="60vh" p={2}>
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Stack>
      );
    }

    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="60vh"
        p={2}
        onClick={handleOpen}
      >
        <Stack
          width="100%"
          height="100%"
          sx={{
            backgroundColor: alpha(theme.palette.background.paper, 0.8),
            borderRadius: 2,
            boxShadow: `0px 0px 30px ${alpha('#000', 1)}`,
            border: `1px solid  ${alpha('#fff', 0.1)}`,
            borderStyle: 'inset',
            backgroundImage: `url('${item.cover}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Stack
            spacing={2}
            p={2}
            width="100%"
            height="100%"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backdropFilter: 'blur(15px)',
              backgroundColor: alpha(theme.palette.background.paper, 0.7),
              borderRadius: 2,
              boxShadow: `0px 0px 30px ${alpha('#000', 1)}`,
              border: `1px solid  ${alpha('#fff', 0.1)}`,
              borderStyle: 'inset',
            }}
          >
            <Box
              className="img"
              component="img"
              src={item.cover}
              alt="highlight cover"
              width="100%"
              height="inherit"
              sx={{
                aspectRatio: '6/3',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: 3,
                border: `1px solid  ${alpha('#fff', 0.1)}`,
                borderStyle: 'inset',
                boxShadow: `0px 0px 30px ${alpha('#000', 0.5)}`,
                filter: item.isGray ? 'grayscale(100%)' : 'none',
              }}
            />
            <Stack spacing={1} width="100%" alignItems="center">
              <Stack width="100%">
                <Typography
                  textAlign="center"
                  variant="h6"
                  fontWeight={800}
                  sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
                >
                  {item.title}
                </Typography>
                <Typography textAlign="center" variant="body2" fontWeight={600}>
                  {item.insight}
                </Typography>
                <Typography textAlign="center" variant="body2">
                  {item.city}
                </Typography>
              </Stack>
              <Button
                color="uncolored"
                size="small"
                variant="contained"
                startIcon={<theme.icons.see />}
                onClick={handleOpen}
              >
                {t(`See the ${type}`, { ns: 'cta' })}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  if (!item) {
    return null;
  }

  if (!Device.isTablet) {
    if (isPortrait) {
      return (
        <Stack justifyContent="flex-start" sx={styles.highlight}>
          <Stack mt={10} height="75%" justifyContent="space-between">
            <Extra type={type} text={item.insight} city={item.city} />
            <Insight type={type} item={item} maxWidth="90%" />
          </Stack>
        </Stack>
      );
    }

    return (
      <Stack justifyContent="flex-start" sx={styles.highlight}>
        <Stack mt={10} height="75%" justifyContent="space-between">
          <Extra type={type} text={item.insight} city={item.city} />
          <Insight type={type} item={item} maxWidth="50%" />
        </Stack>
      </Stack>
    );
  }

  // desktop
  if (isPortrait) {
    return (
      <Stack justifyContent="flex-start" sx={styles.highlight}>
        <Stack
          mt={10}
          height="75%"
          justifyContent="space-between"
          // sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.5) }}
        >
          <Extra type={type} text={item.insight} city={item.city} />
          <Insight type={type} item={item} maxWidth="90%" />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack justifyContent="flex-start" sx={styles.highlight}>
      <Stack mt={10} height="75%" justifyContent="space-between">
        <Extra type={type} text={item.insight} city={item.city} />
        <Insight type={type} item={item} maxWidth="50%" />
      </Stack>
    </Stack>
  );
}
