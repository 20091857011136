import React from 'react';
import { useTheme, styled, darken } from '@mui/material';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';

import { SearchSuggest, SpotTypeEnum } from '@graphql';

interface TypeFormFieldProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Option {
  value: string;
  label: string;
  description: string;
  category: string;
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  zIndex: 1,
  color: theme.palette.text.primary,
  backgroundColor: darken(theme.palette.primary.main, 0.3),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const MyListSubheader = (props: ListSubheaderProps & { muiSkipListHighlight: boolean }) => {
  const { muiSkipListHighlight, sx = {}, ...other } = props;

  return (
    <ListSubheader
      sx={{
        ...sx,
        color: 'white',
        backgroundColor: 'black',
      }}
      {...other}
    />
  );
};

export const TypeFormField = (props: TypeFormFieldProps) => {
  const { open, setOpen } = props;
  const [selected, setSelected] = React.useState<Option[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('spot');
  const theme = useTheme();

  const options = Object.values(SpotTypeEnum).map((type) => ({
    category: type.split('_')[0],
    label: t(`types.${type}.label`),
    value: type,
    description: t(`types.${type}.description`),
  }));

  React.useEffect(() => {
    const types = searchParams.get('type')?.split('+') || ([] as SearchSuggest[]);
    if (types.length) {
      const selectedOptions = options.filter((option) => types.includes(option.value));
      setSelected(selectedOptions);
    }
  }, []);

  // handle mobile close
  React.useEffect(() => {
    if (typeof open === 'boolean' && !open) {
      handleApplySelected();
    }
  }, [open]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: Option) => `${option.label}`,
    limit: 10,
    trim: true,
    ignoreAccents: true,
    ignoreCase: true,
  });

  const handleApplySelected = () => {
    if (selected.length) {
      const type = selected.map((option) => option.value.trim()).join('+');
      searchParams.set('type', type);
    } else {
      searchParams.delete('type');
    }
    setSearchParams(searchParams);
  };

  const handleCloseMobileSelect = () => {
    handleApplySelected();
    setOpen?.(false);
  };

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    const selectedOptions = options.filter((option) => selectedValues.includes(option.value));
    setSelected(selectedOptions);
  };

  if (Device.isMobileOnly) {
    const groupedOptions = options.reduce((acc, option) => {
      if (!acc[option.category]) {
        acc[option.category] = [];
      }
      acc[option.category].push(option);
      return acc;
    }, {} as Record<string, Option[]>);

    return (
      <Select
        multiple
        fullWidth
        size="small"
        value={selected.map((option) => option.value)}
        onChange={handleSelectChange}
        displayEmpty
        onClose={handleCloseMobileSelect}
        renderValue={(selectedValues: string[]) => {
          if (!selectedValues.length) return t('search.types count', { ns: 'common' });

          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((selected) => (
                <Chip key={selected.value} label={selected.label} color="uncolored" />
              ))}
            </Box>
          );
        }}
      >
        {Object.entries(groupedOptions).map(([category, group]) => [
          <MyListSubheader muiSkipListHighlight>{category}</MyListSubheader>,
          group.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selected.some((item) => item.value === option.value)} />
              <Typography>{option.label}</Typography>
            </MenuItem>
          )),
        ])}
      </Select>
    );
  }

  return (
    <Autocomplete
      multiple
      fullWidth
      autoHighlight
      disableCloseOnSelect
      size="small"
      limitTags={10}
      id="multiple-type-limit-tags"
      openOnFocus
      value={selected}
      onChange={(event, newValue) => {
        const uniqueValues = newValue.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);

        setSelected(uniqueValues);
      }}
      onClose={handleApplySelected}
      options={options}
      getOptionLabel={(option) => option.label}
      filterOptions={(options, state) => {
        if (state.inputValue.trim().length === 0) {
          return options;
        }
        return filterOptions(options, state);
      }}
      groupBy={(option) => option.category}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        optionProps.onClick;
        return (
          <Stack
            key={option.value}
            {...optionProps}
            direction="row"
            width="100%"
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              setSelected((prev) => {
                if (prev.find((item) => item.value === option.value)) {
                  return prev.filter((item) => item.value !== option.value);
                }
                return [...prev, option];
              });
            }}
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" flex={1} spacing={1} justifyContent="flex-start" alignItems="center">
              <Checkbox
                style={{ marginRight: 8 }}
                checked={selected.find((item) => item.value === option.value) !== undefined}
              />
              <Typography>{option.label}</Typography>
            </Stack>
            {Device.isDesktop && (
              <Tooltip title={option.description}>
                <theme.icons.infoOutlined fontSize="small" color="uncolored" />
              </Tooltip>
            )}
          </Stack>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          placeholder={selected.length ? '' : 'type(s)'}
          // placeholder={selected.length ? '' : t('searchLocation.placeholder', { ns: 'field' })}
          // onKeyDown={(event) => {
          //   if (['Enter', 'Escape'].includes(event.key)) {
          //     event.preventDefault();
          //     handleApplySelected();
          //   }
          // }}
          slotProps={{
            input: {
              style: {
                width: '100%',
              },
              ...params.InputProps,
              autoComplete: 'off',
              type: 'search',
            },
          }}
        />
      )}
    />
  );
};
