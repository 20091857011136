import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { loginStatusVar } from '@context';

export function OAuthPage() {
  const navigate = useNavigate();
  const loginStatus = useReactiveVar(loginStatusVar);

  // ensure session is up to date
  React.useEffect(() => {
    (async () => {
      if (!loginStatus.isLoggedIn) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        loginStatusVar({
          ...loginStatus,
          forceGetSession: true,
        });
      }

      navigate('/spots', { replace: true });
    })();
  }, []);

  return null;
}
