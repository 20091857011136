import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useGetSessionLazyQuery, useLoginMutation, useLogoutMutation } from '@graphql';
import { loginStatusVar } from '@context';
import { useToastError } from '@hooks';

export default function useConnection() {
  const loginStatus = useReactiveVar(loginStatusVar);
  const toastError = useToastError();
  const navigate = useNavigate();
  const { t } = useTranslation('authentication');

  const [getSession] = useGetSessionLazyQuery({
    onError: toastError,
    onCompleted: ({ session }) => {
      import.meta.env.DEV && console.log(`[{session}]:`, session);
      loginStatusVar({
        ...loginStatus,
        isLoggedIn: true,
        isSessionExpired: false,
        shouldLogFirst: false,
      });
      toast(t('toast.success', { firstname: session?.firstname }));
      loginStatus.redirection && navigate(loginStatus.redirection);
    },
  });

  const [login, { loading: loginLoading }] = useLoginMutation({
    onError: toastError,
    onCompleted({ login }) {
      getSession();
    },
  });

  const [logout, { client }] = useLogoutMutation({
    async onCompleted() {
      toast(`👋 Adio !`);

      // clear the cache (unlike resetStore, clearStore does not refetch queries)
      await client.clearStore();

      // make sure to clear the cache for the following queries
      localStorage.removeItem('SPOTS_LAST_CALL');
      localStorage.removeItem('EVENTS_LAST_CALL');

      loginStatusVar({
        forceGetSession: true, // get session without cache
        isLoggedIn: false,
        mustFinalizeRegister: false,
        isSessionExpired: false,
        shouldLogFirst: false,
        redirection: null,
      });

      navigate('/spots');
    },
  });

  return React.useMemo(
    () => ({
      logout,
      loginLoading,
      login,
    }),
    [loginLoading]
  );
}
